<script>
  import moment from "moment";
  import ResultValue from "components/common/ResultValue.svelte";

  export let estimatedTaxPayments;

  function nth(n) {
    return ["st", "nd", "rd"][((((n + 90) % 100) - 10) % 10) - 1] || "th";
  }

  function month(date) {
    return moment(date, "YYYY-MM-DD").format("MMMM");
  }

  function day(date) {
    return moment(date, "YYYY-MM-DD").format("D");
  }

  function year(date) {
    return moment(date, "YYYY-MM-DD").format("YYYY");
  }
</script>

<div class="col-sm-12 container widget-div">
  <div class="col-12 col-sm-6 overflow-x__auto">
    <table class="table table-striped flash-table">
      <tbody>
        <tr>
          <td class="header">
            {estimatedTaxPayments["currentTaxYear"]} Projected Federal Tax Due
          </td>
          <td class="value">
            <ResultValue value={estimatedTaxPayments["projectedFederalTaxDue"]} />
          </td>
        </tr>
        <tr>
          <td class="header">
            {estimatedTaxPayments["currentTaxYear"]} Projected
            <em>{estimatedTaxPayments["stateName"]}</em>
            Tax Due
          </td>
          <td class="value">
            <ResultValue value={estimatedTaxPayments["projectedStateTaxDue"]} />
          </td>
        </tr>
        <tr>
          <td class="header">
            {estimatedTaxPayments["previousTaxYear"]} Federal Tax
          </td>
          <td class="value">
            <ResultValue value={estimatedTaxPayments["previousYearFederalTax"]} />
          </td>
        </tr>
        <tr>
          <td class="header">
            {estimatedTaxPayments["previousTaxYear"]}
            <em>{estimatedTaxPayments["stateName"]}</em>
            Tax
          </td>
          <td class="value">
            <ResultValue value={estimatedTaxPayments["previousYearStateTax"]} />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-12 col-sm-6 overflow-x__auto">
    <table class="table table-striped flash-table">
      <tbody>
        <tr>
          <td class="header">
            {estimatedTaxPayments["currentTaxYear"]} Federal AGI
          </td>
          <td class="value">
            <ResultValue value={estimatedTaxPayments["federalAgi"]} />
          </td>
        </tr>
        <tr>
          <td class="header">
            {estimatedTaxPayments["currentTaxYear"]}
            <em>{estimatedTaxPayments["stateName"]}</em>
            AGI
          </td>
          <td class="value">
            <ResultValue value={estimatedTaxPayments["stateAgi"]} />
          </td>
        </tr>
        <tr>
          <td class="header">Federal Safe Harbor</td>
          <td class="value">
            <ResultValue value={estimatedTaxPayments["federalSafeHarbor"]} />
          </td>
        </tr>
        <tr>
          <td class="header">
            <em>{estimatedTaxPayments["stateName"]}</em>
            Safe Harbor
          </td>
          <td class="value">
            <ResultValue value={estimatedTaxPayments["stateSafeHarbor"]} />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="col-sm-12 overflow-x__auto">
    <table class="table table-striped flash-table">
      <thead>
        <tr>
          <th />
          {#each estimatedTaxPayments["quarters"] as column, index}
            {#if index + 1 < estimatedTaxPayments["quarters"].length}
              <th class="table-header">
                {`${index + 1}`}<sup>{`${nth(index + 1)}`}</sup> Quarter
                {month(column["dueDate"])}
                {day(column["dueDate"])}<sup>{`${nth(day(column["dueDate"]))}`}</sup>,
                {year(column["dueDate"])}
              </th>
            {:else}
              <th class="table-header">Annual Totals</th>
            {/if}
          {/each}
        </tr>
      </thead>

      <tbody class="no-border">
        <tr>
          <td class="header">Required Federal Payment</td>
          {#each estimatedTaxPayments["quarters"] as column}
            <td class="value">
              <ResultValue value={column["requiredFederalPayment"]} />
            </td>
          {/each}
        </tr>
        <tr>
          <td class="header">Projected Federal Payment</td>
          {#each estimatedTaxPayments["quarters"] as column}
            <td class="value">
              <ResultValue value={column["projectedFederalPayment"]} />
            </td>
          {/each}
        </tr>
        <tr>
          <td class="header">Payment Made</td>
          {#each estimatedTaxPayments["quarters"] as column}
            <td class="value">
              <ResultValue value={column["federalPaymentMade"]} />
            </td>
          {/each}
        </tr>
        <tr>
          <td class="header">Cumulative Shortfall</td>
          {#each estimatedTaxPayments["quarters"] as column}
            <td class="value negative" class:positive={column["federalCumulativeShortfall"] >= 0}>
              <ResultValue value={column["federalCumulativeShortfall"]} />
            </td>
          {/each}
        </tr>
      </tbody>

      <tbody class="separator">
        <tr>
          <td class="header">
            Required <em>{estimatedTaxPayments["stateName"]}</em>
            Payment
          </td>
          {#each estimatedTaxPayments["quarters"] as column}
            <td class="value">
              <ResultValue value={column["requiredStatePayment"]} />
            </td>
          {/each}
        </tr>
        <tr>
          <td class="header">
            Projected <em>{estimatedTaxPayments["stateName"]}</em>
            Payment
          </td>
          {#each estimatedTaxPayments["quarters"] as column}
            <td class="value">
              <ResultValue value={column["projectedStatePayment"]} />
            </td>
          {/each}
        </tr>
        <tr>
          <td class="header">Payment Made</td>
          {#each estimatedTaxPayments["quarters"] as column}
            <td class="value">
              <ResultValue value={column["statePaymentMade"]} />
            </td>
          {/each}
        </tr>
        <tr>
          <td class="header">Cumulative Shortfall</td>
          {#each estimatedTaxPayments["quarters"] as column}
            <td class="value negative" class:positive={column["stateCumulativeShortfall"] >= 0}>
              <ResultValue value={column["stateCumulativeShortfall"]} />
            </td>
          {/each}
        </tr>
      </tbody>
    </table>
  </div>
</div>

<style lang="scss">
  .container {
    padding: 10px;
  }
  .flash-table {
    border-spacing: 0px;
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border-top: 0px;
        }
      }
    }
    > thead > tr {
      > th,
      > td {
        border-bottom-width: 0px;
      }
    }
  }
  .widget-div {
    display: flex;
    flex-wrap: wrap;
  }
  .value {
    text-align: end;
  }
  .negative,
  .positive {
    font-weight: bold;
  }
  .negative {
    color: red;
  }
  .positive {
    color: green;
  }
  .header {
    font-weight: bold;
  }
  .table-header {
    background-color: #452461;
    color: #ffffff;
  }
  .separator {
    border-top: 10px solid #452461;
  }
</style>
